import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { formatCurrency } from "utils/string";

const Wrapper = styled.div`
  display: flex;
  overflow: hidden;
  padding-right: 0.05em;

  height: 1em;

  line-height: 1;
`;

const Digit: React.FC<{ digit: number; index: number; delay?: number }> = ({
  digit,
  index,
  delay,
}) => (
  <motion.div
    animate={{ translateY: `-${100 * (9 - digit)}%` }}
    initial={{ translateY: `-${100 * (9 - index - 1)}%` }}
    transition={{
      duration: 0.5,
      delay: 0.075 * index + delay,
      type: "spring",
      damping: 11,
    }}
  >
    {new Array(10).fill(0).map((_, n) => (
      <div key={n}>{9 - n}</div>
    ))}
  </motion.div>
);

interface Props {
  value: number;
  isCurrency?: boolean;
  delay?: number;
}

const AnimatedCounter: React.FC<Props> = ({
  value,
  isCurrency = true,
  delay = 0,
}) => {
  let valueString = isCurrency ? formatCurrency(value) : value.toString();
  valueString = valueString.replace(/,/g, "");

  const digitGroups = valueString.split(".").map((group) => group.split(""));

  return (
    <Wrapper>
      {digitGroups.map((digits, groupIndex) => (
        <React.Fragment key={groupIndex}>
          {digits.map((char, charIndex) => {
            const digit = parseInt(char, 10);
            const totalDigits = digits.length;
            return (
              <React.Fragment key={charIndex}>
                <Digit
                  delay={delay}
                  digit={digit}
                  index={charIndex * (groupIndex + 1)}
                />
                {(totalDigits - 1 - charIndex) % 3 === 0 &&
                  charIndex !== totalDigits - 1 && <span>,</span>}
              </React.Fragment>
            );
          })}
          {groupIndex === 0 && <span>.</span>}
        </React.Fragment>
      ))}
    </Wrapper>
  );
};

export default AnimatedCounter;
