export enum EventType {
  userSignupSubmitted = "user_signup_submitted",
  userSignupSuccessful = "user_signup_successful",
  dashboardTabChanged = "dashboard_tab_changed",
}

interface GtmOptions {
  eventCallback?: () => void;
  user_id?: string;
  data?: { [key: string]: any };
}

export const trackEvent = (event: EventType, options: GtmOptions = {}) => {
  const app = window as any;
  app.dataLayer = app.dataLayer || [];
  const eventData: { [key: string]: any } = {};
  const userId = app.GA_USER_ID;
  if (userId) {
    eventData.user_id = userId;
  }
  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    console.log("Track event:", event, options, eventData);
    if (options.eventCallback) {
      options.eventCallback();
    }
  } else {
    app.dataLayer.push({
      event,
      ...options,
      ...eventData,
    });
  }
};
