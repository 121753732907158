import React from "react";
import SidebarLayout from "components/layout/SidebarLayout";
import DrawerLayout from "components/layout/DrawerLayout";
import FormProgressSidebar from "components/ui/FormProgressSidebar";
import FormProgressHeader from "components/ui/FormProgressHeader";
import AppFooter from "components/shared/AppFooter";
import EligibilityFooter from "components/shared/EligibilityFooter";
import { logout } from "utils/api/user";
import { FormTask, FormTaskStatus } from "types/task";
import { getUserProfile } from "store/api/apiSlice";
import OnboardingStep from "./components/OnboardingStep";
import { Task, TaskName } from "./types";
import * as Styled from "./styled";

const TASK_MAP: {
  [name in TaskName]: {
    title: string;
  };
} = {
  [TaskName.signup]: {
    title: "Tell us about yourself",
  },
  [TaskName.payrollLinking]: {
    title: "Link your payroll",
  },
  [TaskName.bankLinking]: {
    title: "Link your checking account",
  },
};

interface Props {
  tasks: Task[];
}

const OnboardingFlow: React.FC<Props> = ({ tasks }) => {
  const { data: userProfile } = getUserProfile();

  const steps = tasks.map((task) => ({
    id: task.name,
    status: task.state,
    ...TASK_MAP[task.name],
  })) as FormTask[];

  const currentStepIndex = steps.findIndex(
    (step) => step.status === FormTaskStatus.ready
  );
  const currentStep = steps[currentStepIndex];

  const partnerSlug = userProfile?.partnerSlug;

  return (
    <SidebarLayout
      sidebar={
        <Styled.SidebarWrapper>
          <FormProgressSidebar
            title="Get started"
            steps={steps}
            navigation={[
              { text: "Log Out", icon: "doorOpen", onClick: logout },
            ]}
            partnerSlug={partnerSlug}
          />
        </Styled.SidebarWrapper>
      }
    >
      <Styled.MobileHeaderWrapper>
        <FormProgressHeader
          currentStepIndex={currentStepIndex}
          steps={steps}
          icon="clipboardListCheck"
          title="Get Started With Reset"
          navigation={[{ text: "Log Out", onClick: logout }]}
        />
      </Styled.MobileHeaderWrapper>
      <DrawerLayout>
        {() => (
          <Styled.ContentWrapper>
            <Styled.Content>
              <OnboardingStep id={currentStep.id as TaskName} />

              <Styled.ContentFooter>
                <EligibilityFooter />

                <AppFooter />
              </Styled.ContentFooter>
            </Styled.Content>
          </Styled.ContentWrapper>
        )}
      </DrawerLayout>
    </SidebarLayout>
  );
};

export default OnboardingFlow;
