import React, { forwardRef, useImperativeHandle, useRef } from "react";
import UILabel from "components/ui/UILabel";
import Icon, { IconName } from "components/ui/Icon";
import {
  usePostSimulateDirectDepositMutation,
  usePostSimulateEarningsMutation,
  usePostSimulateTransactionMutation,
} from "store/api/apiSlice";
import { ModalApi } from "../Modal";
import * as Styled from "./styled";

interface Trigger {
  name: string;
  icon: IconName;
  onClick: () => void;
  isLoading?: boolean;
}

const DemoTriggersModal = (
  _: {},
  forwardedRef: React.ForwardedRef<unknown>
) => {
  const ref = useRef<ModalApi>(null);
  useImperativeHandle(forwardedRef, () => ref.current);
  const [postSimulateTransaction, { isLoading: isSimulateTransactionLoading }] =
    usePostSimulateTransactionMutation();
  const [postSimulateEarnings, { isLoading: isSimulateEarningsLoading }] =
    usePostSimulateEarningsMutation();
  const [
    postSimulateDirectDeposit,
    { isLoading: isSimulateDirectDepositLoading },
  ] = usePostSimulateDirectDepositMutation();

  const simulateEarnings = async () => {
    await postSimulateEarnings();
    ref.current?.dismiss();
  };

  const simulateTransaction = async () => {
    await postSimulateTransaction();
    ref.current?.dismiss();
  };

  const simulateDirectDeposit = async () => {
    await postSimulateDirectDeposit();
    ref.current?.dismiss();
  };

  const triggers: Trigger[] = [
    {
      name: "Earnings",
      icon: "dollar",
      onClick: simulateEarnings,
      isLoading: isSimulateEarningsLoading,
    },
    {
      name: "Transaction",
      icon: "creditCard",
      onClick: simulateTransaction,
      isLoading: isSimulateTransactionLoading,
    },
    {
      name: "Direct Deposit",
      icon: "transfer",
      onClick: simulateDirectDeposit,
      isLoading: isSimulateDirectDepositLoading,
    },
  ];

  // TODO: selectively filter this based on demo type
  const currentTriggers = triggers.slice(0, 2);

  return (
    <Styled.Modal ref={ref}>
      <Styled.Wrapper>
        {currentTriggers.map(({ name, icon, onClick, isLoading }) => (
          <Styled.TriggerButton
            onClick={onClick}
            isLoading={isLoading}
            key={name}
          >
            <Styled.ButtonContent>
              <Icon name={icon} color="violet" size="xl" />
              <UILabel.Large>
                Simulate
                <br />
                {name}
              </UILabel.Large>
            </Styled.ButtonContent>
          </Styled.TriggerButton>
        ))}
      </Styled.Wrapper>
    </Styled.Modal>
  );
};

export default forwardRef(DemoTriggersModal);
