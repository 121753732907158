import React from "react";
import styled from "styled-components";
import LightbulbIcon from "components/ui/Icon/LightbulbIcon";
import UILabel from "components/ui/UILabel";
import { borderRadius, colors, shadows } from "utils/theme";

const Wrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  border-radius: ${borderRadius.small}px;
  background: ${colors.azure}1A;
  box-shadow:
    ${shadows.tile},
    0 0 0 1px ${colors.violet} inset;
`;

const Header = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

interface Props {
  title?: string;
  children: React.ReactNode;
}

const InfoBox: React.FC<Props> = ({ children, title }) => (
  <Wrapper>
    {title && (
      <Header>
        <LightbulbIcon />
        <UILabel.Small bold wideCaps color={`${colors.midnight}CC`}>
          {title}
        </UILabel.Small>
      </Header>
    )}

    <UILabel.Medium color="violet">{children}</UILabel.Medium>
  </Wrapper>
);

export default InfoBox;
