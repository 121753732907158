import React from "react";
import styled, { css } from "styled-components";
import Tile, { TileButton, TileLink, TileRouterLink } from "components/ui/Tile";
import Icon from "components/ui/Icon";
import UILabel from "components/ui/UILabel";
import Tag from "components/ui/Tag";
import { colors, mediaMin } from "utils/theme";
import { MenuItemProps, MenuItemType } from "./types";

interface StyleProps {
  $isCta?: boolean;
}

const getStyles = ({ $isCta }: StyleProps) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 64px;

  ${mediaMin("md")} {
    padding: 0;
    min-height: 72px;

    box-shadow: none;
    background: white;
  }

  ${$isCta &&
  css`
    background: linear-gradient(135deg, ${colors.heliotrope}, ${colors.azure});

    * {
      color: white;
    }

    ${mediaMin("md")} {
      padding: 16px;
      margin: 0 -16px;
      width: auto;
    }
  `}
`;

const StyledTile = styled(Tile)<StyleProps>`
  ${getStyles}
`;

const StyledTileLink = styled(TileLink)<StyleProps>`
  ${getStyles}
`;

const StyledTileRouterLink = styled(TileRouterLink)<StyleProps>`
  ${getStyles}
`;

const StyledTileButton = styled(TileButton)<StyleProps>`
  ${getStyles}
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Main = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 1 auto;
`;

const IconWrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
`;

const Label = styled(UILabel.Medium)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1 1 auto;
`;

const ArrowIcon = styled.div`
  opacity: 0.33;

  svg {
    display: block;
  }
`;

const getComponent = (type: MenuItemType) => {
  switch (type) {
    case MenuItemType.link:
      return StyledTileLink;
    case MenuItemType.button:
      return StyledTileButton;
    case MenuItemType.routerLink:
      return StyledTileRouterLink;
    default:
      return StyledTile;
  }
};

const MenuItem: React.FC<MenuItemProps> = ({
  children,
  type,
  icon,
  isDisabled,
  data,
  tag,
  isCta,
  ...props
}) => {
  const Component = getComponent(type);

  return (
    <Component
      {...data}
      {...props}
      size="sm"
      isDisabled={isDisabled}
      $isCta={isCta}
    >
      <Wrapper>
        <Main>
          {icon && (
            <IconWrapper>
              <Icon name={icon} color={colors.violet} />
            </IconWrapper>
          )}
          <Label bold={isCta}>{children}</Label>
          {tag && <Tag level={tag.level}>{tag.text}</Tag>}
        </Main>

        <ArrowIcon>
          <Icon name="caretRightSolid" size="sm" color={colors.violet} />
        </ArrowIcon>
      </Wrapper>
    </Component>
  );
};

export default MenuItem;
