import styled from "styled-components";
import { NavLink as BaseNavLink } from "react-router-dom";
import ContentLayout from "components/layout/ContentLayout";
import {
  colors,
  fonts,
  mediaMin,
  mediaMax,
  shadows,
  fontSizesUI,
  fontWeights,
} from "utils/theme";
import { ButtonLink } from "../Button";

export const LogoWrapper = styled.div`
  padding: 16px;
  flex: 0 0 auto;

  svg {
    width: 108px;
    display: block;
  }

  ${mediaMax("lg")} {
    display: none;
  }
`;

export const Wrapper = styled.nav`
  max-width: 480px;
  width: 100%;
  margin: 0 auto;

  background: ${colors.midnight};
  color: white;

  ${mediaMin("lg")} {
    display: flex;
    max-width: none;
    padding: 24px;
    height: 100vh;

    box-shadow: ${shadows.tile};
  }
`;

export const NavInner = styled(ContentLayout)`
  ${mediaMin("lg")} {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0;
  }
`;

export const NavItems = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  flex: 1 1 auto;

  ${mediaMax("lg")} {
    flex-direction: row;
    justify-content: center;
    gap: 16px;
  }
`;

export const NavItem = styled.li`
  display: flex;
  align-items: center;

  list-style-type: none;

  ${mediaMax("lg")} {
    width: 96px;
    justify-content: center;
  }
`;

export const Label = styled.div`
  font-size: ${fontSizesUI.sm}px;
  line-height: 1.33;

  ${mediaMin("lg")} {
    font-size: ${fontSizesUI.md}px;

    svg {
      height: 18px;
    }
  }
`;

export const NavLink = styled(BaseNavLink)`
  display: flex;
  gap: 2px;
  flex-direction: column;
  align-items: center;

  color: white;

  font-family: ${fonts.body};
  font-weight: ${fontWeights.normal};
  text-decoration: none;

  ${mediaMin("lg")} {
    width: 100%;
    padding: 16px;
    border-radius: 12px;
    flex-direction: row;
    gap: 8px;

    &.active {
      background: ${colors.azure};
    }

    &:not(.active) {
      ${Label} {
        opacity: 0.7;
      }
    }
  }
`;

export const AdminLink = styled.div`
  flex: 0 0 auto;

  ${mediaMax("lg")} {
    display: none;
  }
`;

export const AdminButtonLink = styled(ButtonLink)`
  background: ${colors.heliotrope};
`;
