import React, { useRef } from "react";
import * as process from "process";
import Button, { ButtonLevel, ButtonSize } from "components/ui/Button";
import DemoTriggersModal from "components/modal/DemoTriggersModal";
import { ModalApi } from "components/modal/Modal";
import environment from "utils/environment";

(window as any).process = process;

const DemoTriggers = () => {
  const modal = useRef<ModalApi>(null);

  if (!environment.enableDemoTriggers) return null;

  const openModal = () => {
    modal.current?.show();
  };

  return (
    <>
      <Button
        onClick={openModal}
        size={ButtonSize.md}
        level={ButtonLevel.secondary}
      >
        Demo
      </Button>

      <DemoTriggersModal ref={modal} />
    </>
  );
};

export default DemoTriggers;
