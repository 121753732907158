import ContentLayout from "components/layout/ContentLayout";
import styled, { keyframes } from "styled-components";
import { Body as BaseBody } from "components/ui/Text";
import { colors, mediaMax, mediaMin } from "utils/theme";

export const Hero = styled.div`
  position: relative;

  padding-bottom: 24px;
`;

export const HeaderContent = styled(ContentLayout)`
  position: relative;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px;
  margin: 0 auto;
  padding-top: 64px;
  align-items: center;

  sup {
    color: color-mix(in srgb, var(--color) 50%, transparent);

    line-height: 1;
    font-size: 0.66em;
  }
`;

export const BackgroundShape = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) scaleX(-1);

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  width: calc(100% + 400px);
  height: calc(100% + 96px);
  overflow: hidden;

  pointer-events: none;

  svg {
    transform: rotate(180deg);

    flex: 0 0 auto;
    width: 100%;
    min-width: 800px;

    * {
      fill: ${colors.azure}11;
    }
  }

  &::before {
    content: "";

    display: block;
    width: 100%;
    flex: 1 1 auto;

    background: ${colors.azure}11;
  }

  ${mediaMax("md")} {
    height: calc(100% + 108px);
    width: calc(100% + 200px);
  }
`;

export const CardContainer = styled.div`
  position: relative;
  z-index: 1;

  display: flex;
  justify-content: center;
`;

export const aFloat = keyframes`
  0% {
    transform: translateY(-2%);
  }
  100% {
    transform: translateY(2%);
  }
`;

export const aShadow = keyframes`
  0% {
    transform: scale(1.2);

    opacity: 0.075;
  }
  100% {
    transform: scale(1);

    opacity: 0.15;
  }
`;

export const CardImage = styled.div`
  position: relative;

  img {
    display: block;
    height: 400px;

    animation: ${aFloat} 2s ease-in-out alternate forwards infinite;
  }
`;

export const CardShadow = styled.div`
  position: relative;
  transform: scaleX(1.1) scaleY(0.2);

  filter: blur(32px);

  &::after {
    content: "";

    display: block;
    width: 66%;
    height: 134px;
    margin: 12px auto -24px;

    background: ${colors.violet};
    border-radius: 9999px;

    animation: ${aShadow} 2s ease-in-out alternate forwards infinite;
    will-change: transform, opacity;
  }
`;

export const HeaderText = styled.div`
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  color: var(--color);
`;

export const Body = styled(BaseBody)``;

export const CtaWrapper = styled.div`
  display: flex;
  padding-top: 24px;
`;

export const Footer = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;

  ${mediaMin("md")} {
    padding-top: 48px;
  }

  ${mediaMin("lg")} {
    display: none;
  }
`;

export const PageContent = styled.div`
  & > * {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;

  ${mediaMin("lg")} {
    padding: 0 24px;
    margin-top: -48px;
  }

  ${mediaMin("xl")} {
    padding: 0 48px;
  }

  ${mediaMax("xl")} {
    ${HeaderContent} {
      display: flex;
    }

    ${CardImage} {
      img {
        height: 320px;
      }
    }

    ${HeaderText} {
      flex: 1 1 auto;
    }
  }

  ${mediaMax("lg")} {
    overflow: hidden;
    padding-bottom: 48px;

    ${HeaderContent} {
      padding-top: 48px;
      padding-bottom: 48px;
    }

    ${HeaderText} {
      gap: 8px;
      padding: 0;

      ${Body} {
        display: none;
      }
    }

    ${BackgroundShape} {
      width: 100%;

      svg {
        min-width: 1024px;
      }
    }

    ${CardContainer} {
      width: fit-content;
    }

    ${CardImage} {
      img {
        height: 192px;

        animation: none;
      }
    }

    ${CardShadow} {
      display: none;
    }

    ${CtaWrapper} {
      display: none;
    }
  }

  ${mediaMax("md")} {
    padding-bottom: 0;

    ${HeaderContent} {
      padding-top: 24px;
      padding-bottom: 0;
    }

    ${BackgroundShape} {
      height: calc(100% + 84px);

      svg {
        min-width: 768px;
      }
    }

    ${CardImage} {
      img {
        height: 192px;
      }
    }
  }
`;
