import React from "react";
import { useFormContext } from "react-hook-form";
import { FormSection, RadioField, SubmitButton } from "components/form";
import UILabel from "components/ui/UILabel";
import styled from "styled-components";
import { ButtonLevel } from "components/ui/Button";
import Link from "components/ui/Link";
import { colors } from "utils/theme";
import { AccountAction } from "./types";
import { getActionVerb } from "./utils";

const LabelText = styled.div`
  display: grid;
  gap: 4px;
`;

const Body = styled(UILabel.Medium)`
  opacity: 0.7;
`;

const Header = styled.div`
  display: grid;
  gap: 12px;
  padding-bottom: 16px;
`;

const Footer = styled.div`
  display: grid;
  gap: 24px;
  width: 100%;
  justify-items: center;

  button {
    width: 100%;
  }
`;

interface Props {
  hasCard: boolean;
}

const Form: React.FC<Props> = ({ hasCard }) => {
  const { watch } = useFormContext();
  const selectedAction = watch("action");

  return (
    <>
      <FormSection>
        <Header>
          <UILabel.Large color={`${colors.midnight}CC`}>
            You may submit a request to the customer support team to perform
            either of the below actions. A member of the Reset team will follow
            up with you via email to confirm before any action is taken.
          </UILabel.Large>

          {hasCard && (
            <UILabel.Medium color={`${colors.midnight}B3`}>
              Please note that taking either of the below actions will involve
              (i) disconnecting your payroll and checking accounts and (ii) the
              closure of the Reset deposit account used to handle repayment of
              your advances. Before completing either of those items, any
              outstanding balance will first need to be repaid through the
              normal Reset repayment process on payday. You will also be
              required to switch your direct deposit from your Reset deposit
              account to another checking account that you own. For any
              additional questions on account closures, please see Section 13 of
              the EWA terms of service{" "}
              <a href="/ewa-terms-of-service" target="_blank">
                here
              </a>{" "}
              or reach out to{" "}
              <a href="mailto:hello@getreset.co">hello@getreset.co</a>.
            </UILabel.Medium>
          )}
        </Header>

        <RadioField
          name="action"
          options={[
            {
              value: AccountAction.disconnect,
              label: (
                <LabelText>
                  <div>
                    <strong>Disconnect</strong> my account
                  </div>
                  <Body>
                    Your payroll and checking accounts will be disconnected
                    {hasCard
                      ? ", your Reset deposit account will be closed, and your Reset card will be frozen. "
                      : ". "}
                    You will still be able to log in.
                  </Body>
                </LabelText>
              ),
            },
            {
              value: AccountAction.delete,
              label: (
                <LabelText>
                  <div>
                    <strong>Close</strong> my account
                  </div>
                  <Body>
                    Your payroll and checking accounts will be disconnected
                    {hasCard
                      ? ", and your Reset deposit account, Reset card, and Reset account will be closed. "
                      : ". "}
                    You will no longer be able to&nbsp;log in.
                  </Body>
                </LabelText>
              ),
            },
          ]}
        />
      </FormSection>

      <Footer>
        <SubmitButton
          disabled={!selectedAction}
          level={
            selectedAction === AccountAction.delete
              ? ButtonLevel.danger
              : ButtonLevel.primary
          }
        >
          {selectedAction
            ? `Request to ${getActionVerb(selectedAction)} my account`
            : "Submit Request"}
        </SubmitButton>

        <Link href="/account" size="lg" color="midnight">
          Cancel
        </Link>
      </Footer>
    </>
  );
};

export default Form;
