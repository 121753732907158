import React from "react";
import UILabel from "components/ui/UILabel";
import * as Styled from "./styled";

const STEPS = [
  {
    title: "Initial spending limit",
    body: (
      <>
        You'll be able to spend up to <strong>$100</strong> until your direct
        deposit first passes through Reset for repayment.{" "}
        <strong>No interest, monthly or late fees!</strong>
      </>
    ),
  },
  {
    title: "Unlocked spending limit",
    body: (
      <>
        After we receive your first direct deposit, your spending limit unlocks
        and will adjust daily based on your income, expenses, and other factors.
      </>
    ),
  },
];

interface Props {
  updateStep: (step: number) => void;
  currentStep: number;
}

const ProbationarySteps: React.FC<Props> = ({ updateStep, currentStep }) => (
  <Styled.StepList>
    {STEPS.map((step, index) => (
      <Styled.Step
        key={step.title}
        $isActive={index === currentStep}
        onClick={() => updateStep(index)}
      >
        <UILabel.Large bold>{step.title}</UILabel.Large>
        <UILabel.Medium>{step.body}</UILabel.Medium>
      </Styled.Step>
    ))}
  </Styled.StepList>
);

export default ProbationarySteps;
