import { AccountAction } from "./types";

export const getActionNoun = (action: AccountAction) => {
  switch (action) {
    case AccountAction.disconnect:
      return "disconnection";
    case AccountAction.delete:
      return "account closure";
    default:
      return action;
  }
};

export const getActionVerb = (action: AccountAction) => {
  switch (action) {
    case AccountAction.disconnect:
      return "disconnect";
    case AccountAction.delete:
      return "close";
    default:
      return action;
  }
};
