import styled, { css, keyframes } from "styled-components";
import LogoMark from "components/ui/LogoMark";
import {
  borderRadius,
  fontSizes,
  fontWeights,
  fonts,
  mediaMax,
} from "utils/theme";
import { BaseButton } from "components/ui/Button";
import { BodyLarge, Eyebrow } from "components/ui/Text";
import Icon from "components/ui/Icon";
import { LinkButton } from "components/ui/Link";
import VisaLogoSvg from "components/ui/VisaLogo";

export const Content = styled.div`
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Info = styled.div`
  display: grid;
  gap: 10px;

  ${mediaMax("md")} {
    gap: 4px;
  }
`;

export const Heading = styled.h3`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;

  button {
    padding-top: 2px;
  }
`;

export const HeadingText = styled(BodyLarge)`
  opacity: 0.8;
  color: currentColor;

  font-family: ${fonts.heading};
  font-weight: normal;
  line-height: 1;
`;

export const ModalButton = styled(BaseButton)`
  display: flex;
  gap: 8px;
  align-items: center;

  svg * {
    color: currentColor;
    opacity: 0.5;
  }
`;

const aFadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Amount = styled.h2`
  display: flex;
  align-items: flex-start;
  margin: 0;

  color: currentColor;

  font-size: 80px;
  font-family: ${fonts.heading};
  font-weight: ${fontWeights.bold};
  letter-spacing: -0.0325em;
  line-height: 1;

  animation: ${aFadeIn} 0.33s ease-out forwards;

  ${mediaMax("md")} {
    font-size: 64px;
  }

  @media screen and (max-width: 360px) {
    font-size: 52px;
  }
`;

export const DollarSign = styled.span`
  display: block;
  margin: 0 -0.05em 0 -0.1em;

  opacity: 0.66;

  font-weight: normal;
  font-size: 0.33em;
  line-height: 1.75;
`;

export const Subheading = styled(Eyebrow)`
  color: rgba(255, 255, 255, 0.7);

  line-height: 1;
  font-weight: ${fontWeights.normal};
  letter-spacing: 0.1875em;
`;

export const DslZeroButton = styled(LinkButton)`
  margin-top: 4px;
  gap: 6px;
`;

export const Logo = styled(LogoMark)`
  width: 64px;

  opacity: 0.33;

  * {
    fill: currentColor;
  }

  ${mediaMax("md")} {
    width: 48px;
  }
`;

export const Spacer = styled.div`
  padding-bottom: 62.5%;
  width: 0;
  flex: 0 0 auto;
  min-height: 200px;
`;

export const Wrapper = styled.div`
  position: relative;

  display: flex;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 30px 30px 24px;
  width: 100%;

  border-radius: ${borderRadius.regular}px;
  background: linear-gradient(-45deg, var(--bg-color-1) 30%, var(--bg-color-2));

  & > * {
    position: relative;
  }

  ${mediaMax("md")} {
    padding: 24px;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex: 1 1 auto;
`;

export const VisaLogo = styled(VisaLogoSvg)<{ $isActive?: boolean }>`
  width: 96px;

  * {
    fill: currentColor;
  }

  ${mediaMax("md")} {
    width: 72px;
  }

  ${(props) =>
    !props.$isActive &&
    css`
      opacity: 0.33;
    `}
`;

export const CardNumber = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  font-size: ${fontSizes.sm}px;
  font-family: monospace;
  letter-spacing: 0.1em;

  button {
    margin-bottom: 1px;
  }
`;

export const DetailsButton = styled(BaseButton)`
  position: relative;
  width: 20px;

  svg {
    display: block;
  }
`;

const aSpin = keyframes`
  0% {
    transform: rotate(0);
  }
  99.9% {
    transform: rotate(179.9deg);
  }
`;

export const SpinnerIcon = styled(Icon)`
  animation: ${aSpin} 1s linear infinite;
`;
