import { MenuItemType, MenuSection } from "components/ui/Menu/types";

export const MENU: MenuSection[] = [
  {
    title: "Admin Home",
    items: [
      {
        type: MenuItemType.link,
        children: "Users",
        icon: "userCircle",
        data: {
          href: "/admin/users",
        },
      },
      {
        type: MenuItemType.link,
        children: "Waitlist",
        icon: "squareList",
        data: {
          href: "/admin/waitlists",
        },
      },
    ],
  },
];
