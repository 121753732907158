import React from "react";
import { useSearchParams } from "react-router-dom";
import {
  getAdminUsers,
  getAdminUsersApplicants,
  getAdminUsersCardholders,
  getAdminUsersEligible,
} from "store/admin/api/apiSlice";
import Loading from "components/ui/Loading";
import { formatDateString } from "utils/date";
import { ApiAdminUserMini } from "utils/api/admin/user";
import Icon from "components/ui/Icon";
import { colors } from "utils/theme";
import Pagination from "components/ui/Pagination";
import { RouterLink } from "components/ui/Link";
import { ApiUserProfile } from "utils/api/user";
import UILabel from "components/ui/UILabel";
import { getUserProfile } from "store/api/apiSlice";
import ExportUserDataButton from "./ExportUserDataButton";
import * as Styled from "./styled";
import StatusTag from "../../components/OnboardingStatusTag";
import Table from "../../components/Table";

const HEADINGS = [
  "ID",
  "Name",
  "Email",
  "Admin",
  "Last step",
  "Last login (PDT)",
  "Created at (PDT)",
];

const FILTERS = [
  { label: "All", group: null },
  { label: "Cardholders", group: "cardholders" },
  { label: "Applicants", group: "applicants" },
  { label: "Eligible", group: "eligible" },
];

const getRow = (user: ApiAdminUserMini, isOtherAdmin) => [
  isOtherAdmin ? (
    user.id.slice(-4)
  ) : (
    <RouterLink to={`/admin/users/${user.id}`} color="azure">
      {user.id.slice(-4)}
    </RouterLink>
  ),
  user.name,
  user.email,
  user.admin && (
    <Icon
      name="statusSuccess"
      color={colors.midnight}
      colorSecondary={colors.leaf}
    />
  ),
  <StatusTag status={user.onboardingStatus} />,
  user.lastSignInAt
    ? formatDateString(user.lastSignInAt, "numericShortWithTime")
    : "",
  formatDateString(user.createdAt, "numericShortWithTime"),
];

const isOtherAdmin = (user: ApiAdminUserMini, userProfile: ApiUserProfile) =>
  user.admin && userProfile?.id !== user.id;

const Users = () => {
  const [params] = useSearchParams();
  const page = parseInt(params.get("page"), 10) || 1;
  const userGroup = params.get("user_group");
  const { data: usersData, isLoading: isLoadingAll } = getAdminUsers(page, {
    skip: !!userGroup,
  });
  const { data: cardholdersData, isLoading: isLoadingCardholders } =
    getAdminUsersCardholders(page, { skip: userGroup !== "cardholders" });
  const { data: applicantsData, isLoading: isLoadingApplicants } =
    getAdminUsersApplicants(page, { skip: userGroup !== "applicants" });
  const { data: eligibleData, isLoading: isLoadingEligible } =
    getAdminUsersEligible(page, { skip: userGroup !== "eligible" });
  const { data: userProfile } = getUserProfile();

  if (
    isLoadingAll ||
    isLoadingCardholders ||
    isLoadingApplicants ||
    isLoadingEligible
  )
    <Loading />;

  const data = (() => {
    switch (userGroup) {
      case "cardholders":
        return cardholdersData;
      case "applicants":
        return applicantsData;
      case "eligible":
        return eligibleData;
      default:
        return usersData;
    }
  })();
  const { users, totalPages } = data || {};

  if (!users) return null;

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.HeaderSection>
          <UILabel.Medium>Show:</UILabel.Medium>
          {FILTERS.map((filter) => (
            <Styled.HeaderFilter
              key={filter.group || "all"}
              to={filter.group ? `?user_group=${filter.group}` : "?"}
              $isActive={userGroup === filter.group}
            >
              {filter.label}
            </Styled.HeaderFilter>
          ))}
        </Styled.HeaderSection>

        <Styled.HeaderSection>
          <ExportUserDataButton />
        </Styled.HeaderSection>
      </Styled.Header>

      <Table>
        <thead>
          <tr>
            {HEADINGS.map((heading) => (
              <th key={heading}>{heading}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              {getRow(user, isOtherAdmin(user, userProfile)).map(
                (cell, index) => (
                  <td key={index} data-heading={HEADINGS[index]}>
                    {cell}
                  </td>
                )
              )}
            </tr>
          ))}
        </tbody>
      </Table>

      {totalPages > 1 && <Pagination current={page} total={totalPages} />}
    </Styled.Wrapper>
  );
};

export default Users;
