import { LabelNode, NodeType } from "../Label/types";

type Field = {
  name: string;
  label: LabelNode[];
  value: string[];
};

export const PRIVACY: Field = {
  name: "glba",
  value: ["glba_privacy_notice"],
  label: [
    {
      type: NodeType.text,
      text: "I have read and understand the ",
    },
    {
      type: NodeType.link,
      text: "Reset Privacy Notice",
      data: {
        href: "/privacy-policy",
      },
    },
    {
      type: NodeType.text,
      text: ".",
    },
  ],
};

export const AGREEMENTS: Field = {
  name: "agreements",
  value: ["card_agreement", "deposit_account_agreement", "bank_privacy_policy"],
  label: [
    {
      type: NodeType.text,
      text: "I have read and accept the ",
    },
    {
      type: NodeType.link,
      text: "Card Agreement",
      data: {
        href: "/card-agreement",
      },
    },
    {
      type: NodeType.text,
      text: ", ",
    },
    {
      type: NodeType.link,
      text: "Deposit Account Agreement",
      data: {
        href: "/deposit-account-agreement",
      },
    },
    {
      type: NodeType.text,
      text: ", and ",
    },
    {
      type: NodeType.link,
      text: "Stearns Bank's Privacy Policy",
      data: {
        href: "/bank-privacy-policy",
      },
    },
    {
      type: NodeType.text,
      text: ".",
    },
  ],
};

export const EWA: Field = {
  name: "ewa",
  value: ["ewa_terms_of_service"],
  label: [
    {
      type: NodeType.text,
      text: "I have read and accept the ",
    },
    {
      type: NodeType.link,
      text: "EWA Terms of Service",
      data: {
        href: "/ewa-terms-of-service",
      },
    },
    {
      type: NodeType.text,
      text: ".",
    },
  ],
};

export const ACH: Field = {
  name: "ach",
  value: ["ach_authorization_agreement"],
  label: [
    {
      type: NodeType.text,
      text: "I have read and accept the ",
    },
    {
      type: NodeType.link,
      text: "ACH Authorization Agreement",
      data: {
        href: "/ach-authorization-agreement",
      },
    },
    {
      type: NodeType.text,
      text: ".",
    },
  ],
};
