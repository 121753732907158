import React from "react";
import Timeline from "components/ui/Timeline";
import Loading from "components/ui/Loading";
import { getDashboardOverview } from "store/api/apiSlice";
import environment from "utils/environment";
import { getDemoEvents, getEvents } from "./utils";

const PaydayTimelineStatic: React.FC = () => {
  const { data: dashboard, isLoading: isLoadingDashboard } =
    getDashboardOverview();

  if (isLoadingDashboard) return <Loading />;

  const events = environment.enableDemoTriggers
    ? getDemoEvents({ dashboard })
    : getEvents({ dashboard });

  return <Timeline events={events} />;
};

export default PaydayTimelineStatic;
