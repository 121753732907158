import React, { useContext } from "react";
import { PageBlock, PageContent, PageHero } from "types/cms";
import LayoutContext, { LayoutName } from "components/context/LayoutContext";
import ContentLayout from "components/layout/ContentLayout";
import hero from "./components/hero";
import block from "./components/block";
import Footnotes from "./components/Footnotes";
import * as Styled from "./styled";

interface Props {
  content: PageContent;
  noLayout?: boolean;
}

const Page: React.FC<Props> = ({ content, noLayout }) => {
  const layout = useContext(LayoutContext);
  const Hero = content.hero
    ? (hero[content.hero.type] as React.FC<PageHero>)
    : null;

  return (
    <Styled.Wrapper
      $withSidebar={layout.name === LayoutName.withSidebar}
      $noLayout={noLayout}
    >
      {Hero && <Hero {...content.hero} />}

      <Styled.ContentBlocks>
        {content.blocks.map((contentBlock, index) => {
          const Block = block[contentBlock.type] as React.FC<PageBlock>;
          const isHero = !Hero && index === 0;
          return Block ? (
            <ContentLayout key={contentBlock.id}>
              <Block {...contentBlock} isHero={isHero} />
            </ContentLayout>
          ) : null;
        })}
      </Styled.ContentBlocks>

      {content.footnotes && (
        <Styled.Footer>
          <Footnotes footnotes={content.footnotes} />
        </Styled.Footer>
      )}
    </Styled.Wrapper>
  );
};

export default Page;
