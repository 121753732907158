import React from "react";
import App from "App";
import UserProfileContainer from "components/container/UserProfileContainer";
import { LayoutName } from "components/context/LayoutContext";
import Navigation from "components/ui/Navigation";
import AppFooter from "components/shared/AppFooter";
import * as Styled from "./styled";

interface Props {
  children: React.ReactNode;
  layoutName?: LayoutName;
}

const AppLayout: React.FC<Props> = ({ children, layoutName }) => {
  const params = new URLSearchParams(window.location.search);
  const hideNavigation = JSON.parse(params.get("hideNavigation"));

  return (
    <App layoutName={layoutName} withAuth>
      <Styled.AppWrapper $hideNavigation={hideNavigation}>
        {!hideNavigation && (
          <UserProfileContainer>
            {({ userProfile }) => (
              <Styled.NavWrapper>
                <Navigation userProfile={userProfile} />
              </Styled.NavWrapper>
            )}
          </UserProfileContainer>
        )}

        <Styled.MainWrapper>
          <Styled.ContentWrapper>{children}</Styled.ContentWrapper>

          <Styled.Footer>
            <AppFooter />
          </Styled.Footer>
        </Styled.MainWrapper>
      </Styled.AppWrapper>
    </App>
  );
};

export default AppLayout;
