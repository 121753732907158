export const sortByDateAscending = (arr: any[], key: string) =>
  [...arr].sort((a, b) => {
    const aDate = new Date(a[key] || null);
    const bDate = new Date(b[key] || null);
    if (aDate > bDate) return 1;
    if (aDate < bDate) return -1;
    return 0;
  });

export const sortByDateDescending = (arr: any[], key: string) =>
  [...arr].sort((a, b) => {
    const aDate = new Date(a[key] || null);
    const bDate = new Date(b[key] || null);
    if (aDate > bDate) return -1;
    if (aDate < bDate) return 1;
    return 0;
  });

export const sortByDayAscending = (arr: any[], key: string) =>
  [...arr].sort((a, b) => {
    const aDay = new Date(a[key] || null).getDate();
    const bDay = new Date(b[key] || null).getDate();
    return aDay - bDay;
  });
