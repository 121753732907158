import React from "react";
import { getNotifications } from "store/api/apiSlice";
import { POLLING_INTERVAL } from "utils/api/common";
import { ApiNotification } from "utils/api/notifications";

interface Props {
  children: (props: {
    notifications: ApiNotification[];
    refetch: () => void;
  }) => React.ReactElement;
}

const NotificationsContainer: React.FC<Props> = ({ children }) => {
  const { data: notifications = [], refetch } = getNotifications({
    pollingInterval: POLLING_INTERVAL,
  });

  return children({ notifications, refetch });
};

export default NotificationsContainer;
