import styled, { css } from "styled-components";
import Tile from "components/ui/Tile";
import { colors, mediaMax } from "utils/theme";
import { TagRouterLink } from "components/ui/Tag";

export const Wrapper = styled(Tile).attrs(() => ({ size: "xl" }))`
  display: flex;
  flex-direction: column;
  gap: 48px;
  overflow-x: auto;

  ${mediaMax("lg")} {
    padding: 16px;

    background: transparent;
    box-shadow: none;
    border-radius: 0;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderSection = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  align-items: center;
`;

export const HeaderFilter = styled(TagRouterLink)<{ $isActive?: boolean }>`
  ${(props) =>
    !props.$isActive &&
    css`
      background: white;
      color: ${colors.midnight};
      border: 1px solid ${colors.flax};
    `}
`;
