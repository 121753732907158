import React from "react";
import { WysiwygBlock } from "types/cms";
import * as Styled from "./styled";
import CtaButton from "../../CtaButton";
import Header from "../../Header";

const Wysiwyg: React.FC<WysiwygBlock> = ({
  isHero,
  data: { heading, body, cta },
}) => (
  <Styled.Wrapper>
    {(heading || body) && (
      <Header heading={heading} body={body} isHero={isHero} />
    )}
    {cta && (
      <Styled.CtaWrapper>
        <CtaButton {...cta} />
      </Styled.CtaWrapper>
    )}
  </Styled.Wrapper>
);

export default Wysiwyg;
