import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./Root";
import Home from "./pages/Home";
import Users from "./pages/Users";
import User from "./pages/User";
import Waitlist from "./pages/Waitlist";
import CardTransactions from "./pages/CardTransactions";

const router = createBrowserRouter([
  {
    path: "/admin",
    element: <Root />,
    handle: { title: "Admin Home" },
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "users",
        handle: {
          title: "Users",
        },
        children: [
          {
            path: "",
            element: <Users />,
          },
          {
            path: ":id",
            element: <User />,
            handle: {
              title: "Detail",
            },
          },
        ],
      },
      {
        path: "card-transactions",
        element: <CardTransactions />,
      },
      {
        path: "waitlists",
        element: <Waitlist />,
      },
    ],
  },
]);

const AdminView = () => <RouterProvider router={router} />;

export default AdminView;
