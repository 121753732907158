import React from "react";
import { getUserProfile } from "store/api/apiSlice";
import { ApiUserProfile } from "utils/api/user";

export interface ChildrenRendererProps {
  userProfile: ApiUserProfile;
}
export interface ChildrenRenderer {
  (props: ChildrenRendererProps): React.ReactElement;
}
export interface Props {
  children: ChildrenRenderer;
}

const UserProfileContainer: React.FC<Props> = (props) => {
  const { data: userProfile } = getUserProfile({});

  return props.children({ userProfile, ...props });
};

export default UserProfileContainer;
