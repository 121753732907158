import React from "react";
import { getUserProfile } from "store/api/apiSlice";
import Loading from "components/ui/Loading";
import DocumentList from "components/shared/DocumentList";
import UILayout from "components/layout/UILayout";
import { Heading } from "components/ui/Text";
import { ResetDocument } from "components/shared/DocumentList/types";
import { BASIC_DISCLOSURES, CARDHOLDER_DISCLOSURES } from "./constants";
import { Wrapper } from "../styled";

const Documents: React.FC = () => {
  const { data: userProfile, isLoading } = getUserProfile();

  if (isLoading) return <Loading />;

  const documents = [
    ...BASIC_DISCLOSURES,
    ...(userProfile?.currentCardAccount ? CARDHOLDER_DISCLOSURES : []),
  ] as ResetDocument[];

  return (
    <UILayout>
      <Wrapper>
        <Heading.H3 tag="h1">Documents</Heading.H3>

        <DocumentList documents={documents} />
      </Wrapper>
    </UILayout>
  );
};

export default Documents;
