import React from "react";
import { useMatches } from "react-router-dom";
import DemoTriggers from "components/shared/DemoTriggers";
import Icon from "components/ui/Icon";
import Button, { ButtonLink, ButtonSize } from "components/ui/Button";
import PartnerLogoLockup from "components/ui/PartnerLogoLockup";
import { colors } from "utils/theme";
import { ApiUserProfile } from "utils/api/user";
import { usePostAdminUserStopImpersonatingMutation } from "store/api/apiSlice";
import * as Styled from "./styled";
import { NAVIGATION } from "./constants";
import { pathMatchesRoute } from "./utils";

interface Props {
  userProfile: ApiUserProfile;
}

const Navigation: React.FC<Props> = ({ userProfile }) => {
  const matches = useMatches();

  const [postStopImpersonatingUser] =
    usePostAdminUserStopImpersonatingMutation();
  const stopImpersonatingUser = async () => {
    await postStopImpersonatingUser();
    window.location.replace(`/admin/users/${userProfile.id}`);
  };

  return (
    <Styled.Wrapper>
      <Styled.NavInner>
        <Styled.LogoWrapper>
          <PartnerLogoLockup partnerSlug={userProfile.partnerSlug} />
        </Styled.LogoWrapper>

        <Styled.NavItems>
          {NAVIGATION.map((item) => {
            const isActive = matches.some(pathMatchesRoute(item));

            return (
              <Styled.NavItem key={item.title}>
                <Styled.NavLink
                  to={`/${item.path}`}
                  data-turbo="false"
                  $isActive={isActive}
                >
                  <Icon
                    name={isActive ? item.iconActive : item.icon}
                    color={colors.violet}
                  />
                  <Styled.Label $isActive={isActive}>{item.title}</Styled.Label>
                </Styled.NavLink>
              </Styled.NavItem>
            );
          })}
        </Styled.NavItems>

        <Styled.FooterLinks>
          <DemoTriggers />

          {userProfile.impersonating && (
            <Button size={ButtonSize.md} onClick={stopImpersonatingUser}>
              Stop Viewing as User
            </Button>
          )}

          {userProfile.admin && (
            <ButtonLink href="/admin" size={ButtonSize.md}>
              Admin
            </ButtonLink>
          )}
        </Styled.FooterLinks>
      </Styled.NavInner>
    </Styled.Wrapper>
  );
};

export default Navigation;
