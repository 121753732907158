import React from "react";
import { TimelineEventStepStatus } from "components/ui/Timeline";
import { ApiDashboardOverview } from "utils/api/dashboard";
import dayjs from "dayjs";
import { formatCurrency } from "utils/string";

export const getEvents = ({
  dashboard,
}: {
  dashboard: ApiDashboardOverview;
}) => {
  if (!dashboard) return [];

  const { accounts } = dashboard;

  const payPeriodEndEvent = {
    data: () => ({
      title: "End of Pay Period",
      description:
        "Your earnings will continue to count toward your spending limit until payday.",
    }),
  };

  const paydayEvent = {
    data: () => ({
      title: "Payday 🎉",
      description: (
        <>
          Once we receive your paycheck, we'll forward the remainder to your
          linked checking account. Processing time depends on{" "}
          {accounts?.primaryBank || "your bank"}, but in most cases your deposit
          will arrive in a few hours.
        </>
      ),
      steps: [
        {
          status: TimelineEventStepStatus.incomplete,
          title: "Paycheck received",
          subtitle: accounts?.payroll || "Payroll provider",
        },
        {
          status: TimelineEventStepStatus.incomplete,
          title: "Advances repaid",
          subtitle: "Reset card",
        },
        {
          status: TimelineEventStepStatus.incomplete,
          title: "Remainder deposited",
          subtitle: accounts?.primaryBank || "Checking account",
        },
      ],
    }),
  };

  return [payPeriodEndEvent, paydayEvent];
};

export const getDemoEvents = ({
  dashboard,
}: {
  dashboard: ApiDashboardOverview;
}) => {
  if (!dashboard) return [];

  const { accounts, safeToSpendComponents } = dashboard;
  const { accruedNetEarnings, outstandingResetTransactions } =
    safeToSpendComponents;

  const payPeriodEndEvent = {
    date: dayjs().subtract(3, "day").toDate().toISOString(),
    data: () => ({
      title: "End of Pay Period",
    }),
  };

  const paydayEvent = {
    date: dayjs().toDate().toISOString(),
    data: () => ({
      title: "Payday 🎉",
      description: (
        <>
          You got paid! Your outstanding transactions were automatically repaid.
        </>
      ),
      steps: [
        {
          status: TimelineEventStepStatus.complete,
          title: "Paycheck received",
          subtitle: accounts?.payroll || "Payroll provider",
          detail: formatCurrency(accruedNetEarnings, true),
        },
        {
          status: TimelineEventStepStatus.complete,
          title: "Advances repaid",
          subtitle: "Reset card",
          detail: formatCurrency(outstandingResetTransactions, true),
        },
        {
          status: TimelineEventStepStatus.complete,
          title: "Net paycheck",
          subtitle: accounts?.primaryBank || "Checking account",
          detail: formatCurrency(
            accruedNetEarnings - outstandingResetTransactions,
            true
          ),
        },
      ],
    }),
  };

  return [payPeriodEndEvent, paydayEvent];
};
