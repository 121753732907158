import React from "react";
import UILabel from "components/ui/UILabel";
import InfoBox from "components/ui/InfoBox";
import PaydayTimelineStatic from "components/shared/PaydayTimelineStatic";
import Entry from "components/shared/ContentfulPage/Entry";
import environment from "utils/environment";
import * as Styled from "./styled";
import DashboardTile from "../../DashboardTile";

const PaydayTab = () => (
    <Styled.Wrapper>
      <DashboardTile>
        <Styled.Main>
          {!environment.enableDemoTriggers && (
            <InfoBox>
              <strong>Coming soon:</strong> Track your paycheck live on payday.
            </InfoBox>
          )}
          <Styled.TimelineWrapper>
            <PaydayTimelineStatic />
          </Styled.TimelineWrapper>
        </Styled.Main>
      </DashboardTile>

      <Styled.FaqsWrapper>
        <UILabel.Small bold caps color="azure">
          Frequently Asked Questions
        </UILabel.Small>
        <Entry
          slug={
            environment.enableDemoTriggers ? "payday-faqs-demo" : "payday-faqs"
          }
          section="account"
          noLayout
          preview
        />
      </Styled.FaqsWrapper>
    </Styled.Wrapper>
  );

export default PaydayTab;
