import React, { useEffect, useRef } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import Button from "components/ui/Button";
import {
  InputField,
  CheckboxField,
  FormSection,
  FormMessage,
} from "components/form";
import { Body, Heading } from "components/ui/Text";
import Notification from "components/ui/Notification";
import { SignupSelections } from "types/general";
import EligibilityFooter from "components/shared/EligibilityFooter";
import BankQuestionnaire from "../BankQuestionnaire";
import PayrollQuestionnaire from "../PayrollQuestionnaire";
import * as Styled from "./styled";
import { Eligibility } from "../../types";

interface Props {
  isSubmitting: boolean;
  hasError: { bank: boolean; payroll: boolean };
  updateHasError: (key: "bank" | "payroll") => (e: boolean) => void;
  updateEligibility: (key: "bank" | "payroll") => (e: Eligibility) => void;
  eligibility: { bank: Eligibility; payroll: Eligibility };
  initialParams: SignupSelections;
}

const SignupForm: React.FC<Props> = ({
  isSubmitting,
  updateEligibility,
  eligibility,
  updateHasError,
  hasError,
  initialParams,
}) => {
  const flags = useFlags();
  const payrollRef = useRef(null);
  const bankRef = useRef(null);

  useEffect(() => {
    if (hasError.payroll || hasError.bank) {
      const ref = hasError.bank ? bankRef : payrollRef;
      const rect = ref.current?.getBoundingClientRect();
      if (rect?.top) {
        window.scrollTo({
          top: window.scrollY + rect.top,
          left: 0,
          behavior: "smooth",
        });
      }
    }
  }, [hasError]);

  return (
    <>
      <Styled.FormHeader>
        <Styled.FormTitle tag="h1">Sign up for the app!</Styled.FormTitle>
        {!flags["rf-6-card-launch"] && (
          <Notification showIcon={false}>
            The Reset card is <u>currently invite-only</u>. Sign up for our app
            to get earlier access to apply for the card.
          </Notification>
        )}
      </Styled.FormHeader>

      <FormSection>
        <Heading.H3 tag="h2">Tell us about yourself</Heading.H3>

        <p className="form-help">
          Please enter your legal name for ID verification.
        </p>

        <InputField
          name="user.first_name"
          label="First name"
          placeholder="Val"
          required
        />

        <InputField
          label="Last name"
          name="user.last_name"
          placeholder="Clark"
          required
        />

        <InputField
          name="user.email"
          label="Email"
          required
          placeholder="val.clark@example.com"
          autoComplete="email"
          type="email"
        />
      </FormSection>

      <FormSection>
        <Styled.SectionHeading>
          <Heading.H3 tag="h2">
            Where do you bank?
            <div ref={payrollRef} />
          </Heading.H3>

          <Body>
            Please select the bank or credit union where you do direct deposit.
          </Body>
        </Styled.SectionHeading>

        {hasError.bank && (
          <FormMessage>Please fill out this section</FormMessage>
        )}

        <BankQuestionnaire
          initialValue={initialParams?.bank}
          setEligibility={updateEligibility("bank")}
          setHasError={updateHasError("bank")}
        />

        {process.env.NODE_ENV === "development" && (
          <div>{eligibility.bank}</div>
        )}
      </FormSection>

      <FormSection>
        <Styled.SectionHeading>
          <Heading.H3 tag="h2">
            How are you paid?
            <div ref={payrollRef} />
          </Heading.H3>

          <Body>Tell us about your primary source of income.</Body>
        </Styled.SectionHeading>

        {hasError.payroll && (
          <FormMessage>Please fill out this section</FormMessage>
        )}

        <PayrollQuestionnaire
          initialValue={initialParams?.payroll}
          setEligibility={updateEligibility("payroll")}
          setHasError={updateHasError("payroll")}
        />

        {process.env.NODE_ENV === "development" && (
          <div>{eligibility.payroll}</div>
        )}
      </FormSection>

      <FormSection>
        <CheckboxField
          required
          name="checked"
          value="yes"
          label={
            <span>
              By checking this box, you agree to our{" "}
              <a target="_blank" href="/e-sign-consent-agreement">
                E-Sign Consent Agreement
              </a>
              ,{" "}
              <a target="_blank" href="/terms-of-service">
                Terms of Service
              </a>
              , and{" "}
              <a target="_blank" href="/privacy-policy">
                Privacy Policy
              </a>
              .
            </span>
          }
        />
      </FormSection>

      <div>
        <Button
          type="submit"
          name="commit"
          className="button"
          fullWidth
          isLoading={isSubmitting}
        >
          Agree &amp; Continue
        </Button>
      </div>

      <EligibilityFooter />
    </>
  );
};

export default SignupForm;
