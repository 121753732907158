import React, { useEffect, useState } from "react";
import FormContainer from "components/form";
import {
  getIdentityVerificationStatus,
  getUserProfile,
  usePostPersonalInfosMutation,
} from "store/api/apiSlice";
import { ApiPersonalInfosRequest } from "utils/api/user";
import { VerificationStatus } from "types/verification";
import { InfoType } from "../types";
import IdentityForm from "./IdentityForm";
import ReviewInfo from "./ReviewInfo";
import Success from "./Success";
import Loading from "./Loading";

interface Props {
  showInfo: (infoType: InfoType) => () => void;
}

const IdentityVerification: React.FC<Props> = ({ showInfo }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [showName, setShowName] = useState(false);
  const { data: user } = getUserProfile();
  const [postPersonalInfos] = usePostPersonalInfosMutation();
  const { data: identityVerification, ...identityData } =
    getIdentityVerificationStatus({
      pollingInterval: isVerifying ? 5000 : undefined,
    });
  const verificationStatus = identityVerification?.currentStatus;

  const submitToReview = () => {
    setShowReview(true);
  };

  const verifyIdentity = async ({ phoneNumber, dateOfBirth, ssn, address }) => {
    const dob = new Date(dateOfBirth).toISOString().replace(/T.+/, "");
    const data = {
      phoneNumber: phoneNumber.replace(/[^0-9]/g, ""),
      dateOfBirth: dob,
      ssn,
      address,
    } as ApiPersonalInfosRequest;
    const res = await postPersonalInfos(data);

    if (res) {
      setIsVerifying(true);
    }
  };

  const onSubmit = (values) => {
    setIsLoading(true);

    if (values.confirmed) {
      verifyIdentity(values);
    } else {
      submitToReview();
    }

    setIsLoading(false);
  };

  const onClickBack = () => {
    setShowReview(false);
    setShowName(true);
  };

  useEffect(() => {
    if (verificationStatus) {
      setIsVerifying(true);

      if (verificationStatus === VerificationStatus.verifiedAutomatically) {
        window.setTimeout(() => {
          window.location.reload();
        }, 5000);
      }
    }
  }, [verificationStatus]);

  if (identityData.isLoading) {
    return <Loading />;
  }
  if (isVerifying) {
    return <Success status={verificationStatus as VerificationStatus} />;
  }
  return (
    <FormContainer onSubmit={onSubmit}>
      {showReview ? (
        <ReviewInfo onClickBack={onClickBack} user={user} />
      ) : (
        <IdentityForm
          user={user}
          isLoading={isLoading}
          showInfo={showInfo}
          showName={showName}
        />
      )}
    </FormContainer>
  );
};

export default IdentityVerification;
