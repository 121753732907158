import React from "react";
import TileBlock from "components/cms/components/block/Tile";
import HeaderDivider from "components/cms/components/hero/Card/HeaderDivider";
import { ButtonLink } from "components/ui/Button";
import { Body, Eyebrow, Heading } from "components/ui/Text";
import cardImage from "assets/images/general/reset-card-angle.png";
import { getUserProfile } from "store/api/apiSlice";
import content from "./content";
import * as Styled from "./styled";

const ApplyButton: React.FC<{ canApply: boolean }> = ({ canApply }) =>
  canApply ? (
    <ButtonLink href="/apply">Apply Now!</ButtonLink>
  ) : (
    <ButtonLink disabled>Application Coming Soon</ButtonLink>
  );

const CardBenefitsView: React.FC = () => {
  const { data: userProfile } = getUserProfile();

  return (
    <Styled.Wrapper>
      <Styled.Hero>
        <Styled.BackgroundShape>
          <HeaderDivider />
        </Styled.BackgroundShape>

        <Styled.HeaderContent>
          <Styled.HeaderText>
            <Eyebrow>Reset Card</Eyebrow>
            <Heading.H1>Benefits</Heading.H1>
            <Styled.Body>
              It's your money, just sooner and without the senseless fees. No
              credit check and no security deposit.
            </Styled.Body>
            <Styled.CtaWrapper>
              <ApplyButton canApply={userProfile?.eligibleForCard} />
            </Styled.CtaWrapper>
          </Styled.HeaderText>

          <Styled.CardContainer>
            <Styled.CardImage>
              <img src={cardImage} />

              <Styled.CardShadow />
            </Styled.CardImage>
          </Styled.CardContainer>
        </Styled.HeaderContent>
      </Styled.Hero>

      <Styled.PageContent>
        <TileBlock {...content.tileBlock} />
      </Styled.PageContent>

      <Styled.Footer>
        <Body>You're on the waitlist!</Body>
        <ApplyButton canApply={userProfile?.eligibleForCard} />
      </Styled.Footer>
    </Styled.Wrapper>
  );
};

export default CardBenefitsView;
