import React from "react";
import styled from "styled-components";
import {
  FONT_SIZE_KEYS,
  colors,
  fontSizes,
  fontSizesMobile,
  fontWeights,
  letterSpacings,
  lineHeights,
  mediaMax,
} from "utils/theme";

const baseStyles = `
  margin: 0;
`;

const baseHeadingStyles = `
  ${baseStyles}
  -webkit-font-smoothing: antialiased;
`;

const baseBodyStyles = `
  ${baseStyles}

  font-weight: ${fontWeights.normal};
`;

const getHeadingStyles = (size) => `
  font-weight: ${
    ["xxl", "xl", "lg", "md"].includes(size)
      ? fontWeights.black
      : fontWeights.bold
  };
  font-size: ${fontSizes[size]}px;
  letter-spacing: ${letterSpacings[size]}em;
  line-height: ${lineHeights[size]};
`;

interface Props {
  tag?: React.ElementType;
  color?: string;
  children?: React.ReactNode;
  renderHtml?: boolean;
}

const Text: React.FC<Props> = ({
  tag = "h1",
  children,
  renderHtml,
  ...props
}) => {
  const Component = tag;
  return renderHtml ? (
    <Component {...props} dangerouslySetInnerHTML={{ __html: children }} />
  ) : (
    <Component {...props}>{children}</Component>
  );
};

const StyledText = styled(Text)`
  color: ${(props) => colors[props.color] || props.color};
`;

export const Heading: { [key: string]: React.FC<Props> } = new Array(5)
  .fill(0)
  .reduce(
    (acc, _, index) => ({
      ...acc,
      [`H${index + 1}`]: styled(
        ({ tag = `h${index + 1}`, children, ...props }) => (
          <StyledText tag={tag as React.ElementType} {...props}>
            {children}
          </StyledText>
        )
      )`
        ${baseHeadingStyles}

        ${getHeadingStyles(FONT_SIZE_KEYS[FONT_SIZE_KEYS.length - index - 1])}

        ${mediaMax("lg")} {
          font-size: ${fontSizesMobile[
            FONT_SIZE_KEYS[FONT_SIZE_KEYS.length - index - 1]
          ]}px;
        }
      `,
    }),
    {}
  );

export const Eyebrow: React.FC<Props> = styled(
  ({ tag = "h2", children, color = colors.azure, ...props }) => (
    <StyledText tag={tag as React.ElementType} color={color} {...props}>
      {children}
    </StyledText>
  )
)`
  ${baseStyles}

  font-size: ${fontSizes.xs}px;
  line-height: ${lineHeights.xs};
  font-weight: ${fontWeights.bold};
  letter-spacing: 0.11em;
  text-transform: uppercase;

  ${mediaMax("lg")} {
    font-size: ${fontSizesMobile.xs}px;
  }
`;

const DEFAULT_BODY_COLOR = `color-mix(in srgb, ${colors.midnight} 80%, transparent)`;

export const BodyLarge: React.FC<Props> = styled(
  ({ tag = "p", children, color = DEFAULT_BODY_COLOR, ...props }) => (
    <StyledText tag={tag as React.ElementType} color={color} {...props}>
      {children}
    </StyledText>
  )
)`
  ${baseBodyStyles}

  line-height: ${lineHeights.md};
  font-size: ${fontSizes.md}px;

  ${mediaMax("lg")} {
    font-size: ${fontSizesMobile.md}px;
  }
`;

export const Body: React.FC<Props> = styled(
  ({ tag = "p", children, color = DEFAULT_BODY_COLOR, ...props }) => (
    <StyledText tag={tag as React.ElementType} color={color} {...props}>
      {children}
    </StyledText>
  )
)`
  ${baseBodyStyles}

  line-height: ${lineHeights.sm};
  font-size: ${fontSizes.sm}px;

  ${mediaMax("lg")} {
    font-size: ${fontSizesMobile.sm}px;
  }
`;

export const BodySmall: React.FC<Props> = styled(
  ({ tag = "p", children, color = DEFAULT_BODY_COLOR, ...props }) => (
    <StyledText tag={tag as React.ElementType} color={color} {...props}>
      {children}
    </StyledText>
  )
)`
  ${baseBodyStyles}

  line-height: ${lineHeights.xs};
  font-size: ${fontSizes.xs}px;

  ${mediaMax("lg")} {
    font-size: ${fontSizesMobile.xs}px;
  }
`;
