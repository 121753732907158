import React, {
  useContext,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from "react";
import ReactDOM from "react-dom";
import { v4 as uuid } from "uuid";
import { AnimatePresence } from "framer-motion";
import ModalContext from "components/context/ModalContext";
import Icon from "components/ui/Icon";
import * as Styled from "./styled";

export interface ModalApi {
  show: () => void;
  dismiss: () => void;
}

const Modal = (
  {
    children,
    ...props
  }: {
    children: React.ReactNode;
  },
  ref: React.ForwardedRef<unknown>
) => {
  const id = useRef(uuid());
  const modalRef = useRef<HTMLElement | null>(null);
  const modalContext = useContext(ModalContext);

  const isBrowser = typeof window !== "undefined";

  useEffect(
    () => () => {
      if (modalContext.id === id.current) modalContext.setId(null);
    },
    [modalContext.id, id.current]
  );

  function show() {
    modalContext.setId(null);
    // allows new modal to render
    setTimeout(() => {
      modalContext.setId(id.current);
    }, 0);
  }

  function dismiss() {
    if (modalContext.id === id.current) modalContext.setId(null);
  }

  useImperativeHandle(ref, () => ({ show, dismiss }));

  if (!isBrowser) return null;
  const isShown = Boolean(
    id.current === modalContext.id && modalContext.portal
  );

  return isShown
    ? ReactDOM.createPortal(
        <Styled.Wrapper ref={modalRef} {...props}>
          <AnimatePresence>
            <Styled.ModalPane
              initial="hidden"
              animate="shown"
              exit="hidden"
              variants={{
                shown: { y: 0, opacity: 1 },
                hidden: { y: 20, opacity: 0 },
              }}
              transition={{ duration: 0.33, ease: [0.25, 1, 0.5, 1] }}
            >
              <Styled.Close>
                <Styled.CloseButton
                  onClick={() => dismiss()}
                  className="js-skip-focus"
                >
                  <Icon name="close" size="md" />
                </Styled.CloseButton>
              </Styled.Close>

              <Styled.Main>{children}</Styled.Main>
            </Styled.ModalPane>
          </AnimatePresence>
        </Styled.Wrapper>,
        modalContext.portal || document.body
      )
    : null;
};

export default forwardRef(Modal);
