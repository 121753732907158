import styled from "styled-components";
import { FormTaskStatus } from "types/task";
import { colors, fontSizesUI, fontWeights, mediaMax } from "utils/theme";
import UILabel from "../UILabel";

interface StyledProps {
  $status: FormTaskStatus;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  --padding-x: 48px;
  padding: 48px var(--padding-x);
  overflow: auto;

  background: ${colors.midnight};
  color: white;

  ${mediaMax("xl")} {
    --padding-x: 24px;
  }
`;

export const HomeLink = styled.a`
  padding-bottom: 24px;
`;

export const Heading = styled(UILabel.Small).attrs(() => ({
  bold: true,
  wideCaps: true,
}))`
  margin: 0;

  line-height: 24px;
`;

export const StepList = styled.ol`
  display: grid;
  gap: 24px;
  padding: 0;
  margin: 0;

  list-style-type: none;
  counter-reset: sidebar-item;
`;

export const Step = styled.li`
  display: flex;
  align-items: center;
  gap: 12px;

  counter-increment: sidebar-item;
`;

export const IconWrapper = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  flex: 0 0 auto;

  border-radius: 9999px;

  font-size: ${fontSizesUI.sm}px;
  font-weight: ${fontWeights.bold};

  svg {
    display: none;
  }

  &::after {
    content: counter(sidebar-item);

    display: none;
  }

  ${(props) => {
    switch (props.$status) {
      case FormTaskStatus.complete:
        return `
            background: ${colors.azure};

            svg {
              display: block;

              * {
                stroke-width: 40px;
                stroke: currentColor;
              }
            }
          `;
      case FormTaskStatus.ready:
        return `
            background: ${colors.heliotrope};

            &::after {
              display: block;
            }
          `;
      default:
        return `
            border: 1px solid ${colors.heliotrope};
          `;
    }
  }}
`;

export const Title = styled.div<StyledProps>`
  ${(props) => {
    switch (props.$status) {
      case FormTaskStatus.incomplete:
        return `
            opacity: 0.8;
          `;
      case FormTaskStatus.complete:
        return `
            opacity: 0.5;
          `;
      case FormTaskStatus.ready:
      default:
        return `
          font-weight: ${fontWeights.bold};
        `;
    }
  }}
`;

export const Description = styled.div<StyledProps>`
  margin-top: 6px;

  opacity: 0.7;

  line-height: 1.25em;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1 1 auto;
  padding-top: 48px;
`;

export const FooterLogo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  padding-bottom: 48px;

  * {
    opacity: 0.5;
  }

  img {
    height: 36px;
    opacity: 1;
  }
`;

export const NavItems = styled.div`
  display: grid;
  gap: 12px;
`;

export const NavItem = styled.a`
  display: flex;
  align-items: center;
  gap: 12px;
  appearance: none;
  background: none;
  border: 0;
  color: white;
  cursor: pointer;

  text-decoration: none;

  svg {
    transform: translateY(-1px);

    margin: 0 2px;
    opacity: 0.7;
  }
`;
