// interface

export enum ApiNotificationErrorType {
  monitoring_status_unavailable = "monitoring_status_unavailable",
  direct_deposit_switched_away_from_reset = "direct_deposit_switched_away_from_reset",
}

export enum ApiNotificationLevel {
  success = "SUCCESS",
  info = "INFO",
  warn = "WARN",
  error = "ERROR",
}

export enum ApiNotificationSource {
  pinwheel = "pinwheel",
  plaid = "plaid",
}

export interface ApiNotification {
  id: string;
  title: string | null;
  type: string;
  actedAt: string | null;
  readAt: string | null;
  requireAction: boolean;
  level: ApiNotificationLevel;
  source: ApiNotificationSource | null;
  errorType: ApiNotificationErrorType | null;
}

// maps

const ERROR_TYPE_MAP = {
  [ApiNotificationErrorType.monitoring_status_unavailable]: {
    [ApiNotificationSource.pinwheel]: {
      title: "Please relink your payroll account",
      level: ApiNotificationLevel.error,
    },
    [ApiNotificationSource.plaid]: {
      title: "Please relink your checking account",
      level: ApiNotificationLevel.error,
    },
  },
};

export const NOTIFICATION_MAP = ERROR_TYPE_MAP;

// helpers

interface RawNotification {
  id: string;
  type: string;
  actedAt: string | null;
  readAt: string | null;
  requireAction: boolean;
  params: { errorType: string; source: string };
}

type NotificationTransformer = (
  notifications: RawNotification[]
) => ApiNotification[];

export const notificationTransformer: NotificationTransformer = (
  notifications
) =>
  notifications.map((notification) => {
    let transformed = {
      id: notification.id,
      type: notification.type,
      actedAt: notification.actedAt,
      readAt: notification.readAt,
      requireAction: notification.requireAction,
      level: ApiNotificationLevel.info,
      title: null,
      source: null,
      errorType: null,
    };

    if (notification.type === "IntegrationErrorNotifier::Notification") {
      transformed = {
        ...transformed,
        errorType: notification.params.errorType,
        source: notification.params.source,
        ...NOTIFICATION_MAP[notification.params.errorType][
          notification.params.source
        ],
      };
    }

    return transformed;
  });

export const getIntegrationErrors = (notifications: ApiNotification[]) =>
  notifications.filter(
    (notification) =>
      [ApiNotificationSource.pinwheel, ApiNotificationSource.plaid].includes(
        notification.source
      ) && notification.type === "IntegrationErrorNotifier::Notification"
  );
