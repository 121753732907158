import styled, { css } from "styled-components";
import { borderRadius, colors, shadows } from "utils/theme";

export const FrontWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

export const BackWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateY(180deg);

  width: 100%;
  height: 100%;
`;

export const CardWrapper = styled.div`
  position: relative;
  transform-origin: center;
  transform: rotateY(0);

  box-shadow: ${shadows.large};
  border-radius: ${borderRadius.regular}px;

  transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
`;

export const Wrapper = styled.div<{
  $showDetails: boolean;
  $isActive: boolean;
}>`
  position: relative;
  perspective: 1000px;
  transform: translateZ(0);

  --bg-color-1: ${colors.azure};
  --bg-color-2: ${colors.heliotrope};
  color: white;

  & > * {
    position: relative;
  }

  ${(props) =>
    props.$showDetails &&
    css`
      ${CardWrapper} {
        transform: rotateY(180deg);
      }

      ${FrontWrapper} {
        pointer-events: none;
      }

      ${BackWrapper} {
        z-index: 1;
      }
    `}

  ${(props) =>
    !props.$isActive &&
    css`
      color: ${colors.midnight}AA;

      --bg-color-1: color-mix(in srgb, ${colors.violet} 20%, white);
      --bg-color-2: var(--bg-color-1);
    `};
`;
