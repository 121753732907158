import styled from "styled-components";
import Button from "components/ui/Button";
import { borderRadius, colors, shadows } from "utils/theme";
import BaseModal from "../Modal";

export const Modal = styled(BaseModal)`
  & > * {
    width: 480px;
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  width: 100%;
`;

export const TriggerButton = styled(Button)`
  position: relative;

  padding-bottom: 100%;

  border-radius: ${borderRadius.small}px;
  background: white;
  box-shadow: ${shadows.tile};
  border: 1px solid ${colors.violet};
  color: ${colors.midnight};
`;

export const ButtonContent = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 24px;
`;
