import React from "react";
import camelCaseKeys from "camelcase-keys";
import Logo from "components/ui/Logo";
import { ButtonLink, ButtonSize } from "components/ui/Button";
import UILabel from "components/ui/UILabel";
import UserDropdown, { UserProfile } from "./UserDropdown";
import MobileMenu from "./MobileMenu";
import PartnerBanner from "./PartnerBanner";
import { SITE_NAVIGATION } from "./constants";
import * as Styled from "./styled";

interface Props {
  currentUser?: any;
}

const SiteNavigation: React.FC<Props> = ({ currentUser }) => {
  const userProfile = camelCaseKeys(currentUser) as UserProfile;

  return (
    <>
      <Styled.Wrapper>
        <Styled.NavInner>
          <Styled.LogoWrapper>
            <a href="/" title="Reset home" data-turbo="false">
              <Logo />
            </a>
          </Styled.LogoWrapper>

          <Styled.Menu>
            <Styled.NavLeft>
              <Styled.NavItems>
                {SITE_NAVIGATION.map((item) => (
                  <Styled.NavItem key={item.title}>
                    <Styled.NavLink
                      href={`/${item.path}`}
                      data-turbo="false"
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.pathname = `/${item.path}`;
                      }}
                    >
                      <UILabel.Large bold>{item.title}</UILabel.Large>
                    </Styled.NavLink>
                  </Styled.NavItem>
                ))}
              </Styled.NavItems>
            </Styled.NavLeft>

            <Styled.NavRight>
              {currentUser ? (
                <UserDropdown userProfile={userProfile} />
              ) : (
                <>
                  <Styled.NavLink href="/users/sign_in" data-turbo="false">
                    <UILabel.Large bold>Log In</UILabel.Large>
                  </Styled.NavLink>

                  <ButtonLink
                    size={ButtonSize.md}
                    href="/users/sign_up"
                    data-turbo="false"
                  >
                    Sign Up
                  </ButtonLink>
                </>
              )}

              <Styled.MobileMenuWrapper>
                <MobileMenu />
              </Styled.MobileMenuWrapper>
            </Styled.NavRight>
          </Styled.Menu>
        </Styled.NavInner>
      </Styled.Wrapper>

      <PartnerBanner />
    </>
  );
};

export default SiteNavigation;
