import {
  ResetDocument,
  ResetDocumentType,
} from "components/shared/DocumentList/types";

export const BASIC_DISCLOSURES: ResetDocument[] = [
  {
    title: "E-Sign Consent Agreement",
    data: { url: "/e-sign-consent-agreement" },
    type: ResetDocumentType.Web,
  },
  {
    title: "Terms of Service",
    data: { url: "/terms-of-service" },
    type: ResetDocumentType.Web,
  },
  {
    title: "Privacy Policy",
    data: { url: "/privacy-policy" },
    type: ResetDocumentType.Web,
  },
];

export const CARDHOLDER_DISCLOSURES: ResetDocument[] = [
  {
    title: "Card Agreement",
    data: { url: "/documents/Reset Card Agreement.pdf" },
    type: ResetDocumentType.File,
  },
  {
    title: "Deposit Account Agreement",
    data: { url: "/documents/Reset Deposit Account Agreement.pdf" },
    type: ResetDocumentType.File,
  },
  {
    title: "Stearns Bank's Privacy Policy",
    data: { url: "/documents/Stearns Bank Privacy Policy.pdf" },
    type: ResetDocumentType.File,
  },
  {
    title: "EWA Terms of Service",
    data: { url: "/documents/Reset EWA Terms of Service.pdf" },
    type: ResetDocumentType.File,
  },
  {
    title: "ACH Authorization Agreement",
    data: { url: "/documents/Reset ACH Authorization Agreement.pdf" },
    type: ResetDocumentType.File,
  },
];
