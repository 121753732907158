import styled, { css } from "styled-components";
import { colors, mediaMax, mediaMin } from "utils/theme";

export const ContentBlocks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 72px;

  &:first-child {
    padding-top: 72px;

    ${mediaMax("lg")} {
      padding-top: 36px;
    }
  }

  &:last-child {
    padding-bottom: 128px;

    ${mediaMax("lg")} {
      padding-bottom: 64px;
    }
  }
`;

export const Footer = styled.footer`
  padding-bottom: 96px;
`;

export const Wrapper = styled.div<{
  $withSidebar?: boolean;
  $noLayout?: boolean;
}>`
  --color-background: ${colors.ice};

  display: flex;
  flex-direction: column;
  gap: 80px;
  min-height: calc(100vh - 72px);

  background: var(--color-background);

  ${mediaMax("lg")} {
    gap: 60px;
  }

  ${mediaMax("md")} {
    gap: 40px;
  }

  ${(props) =>
    props.$withSidebar &&
    css`
      --color-background: color-mix(in srgb, ${colors.azure} 3%, white);

      ${mediaMin("lg")} {
        margin: -48px;
      }
    `}

  ${(props) =>
    props.$noLayout &&
    css`
      min-height: 0;
      margin: 0 !important;

      background: none;

      ${ContentBlocks} {
        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
        }
      }
    `}
`;
