import styled, { css } from "styled-components";
import Tag from "components/ui/Tag";
import { colors, mediaMax } from "utils/theme";

const getStatusTagStyles = ({ $status }: { $status: string }) => {
  switch ($status) {
    case "disconnected":
    case "unavailable":
      return css`
        background: ${colors.cerise};
        color: white;
      `;
    case "degraded":
    case "user_action_required":
      return css`
        background: ${colors.cosmos};
      `;
    case "false":
      return css`
        background: ${colors.cerise};
        color: white;
      `;
    case "true":
    case "connected":
    case "active":
      return css`
        background: ${colors.leaf};
      `;
    default:
      return "";
  }
};

export const Wrapper = styled(Tag)<{ $status: string }>`
  width: fit-content;

  color: ${colors.midnight};
  background: ${colors.flax};

  ${getStatusTagStyles}

  ${mediaMax("lg")} {
    margin-top: 6px;
  }
`;
