import React, { useEffect, useState } from "react";
import { BodySmall, Heading } from "components/ui/Text";
import Button from "components/ui/Button";
import FormContainer, { CheckboxField, FormSection } from "components/form";
import MobileFixedFooter from "components/ui/MobileFixedFooter";
import { usePostAcceptancesMutation } from "store/api/apiSlice";
import * as Styled from "../styled";
import Label from "../Label";
import * as FIELDS from "./fields";
import { InfoType } from "../types";
import DslExplanation from "../DslExplanation";

const TERM_FIELDS = [FIELDS.PRIVACY, FIELDS.AGREEMENTS, FIELDS.EWA, FIELDS.ACH];

const getAcceptances = (values: { [key: string]: string | boolean }) =>
  Object.values(values)
    .filter((checked) => checked)
    .reduce(
      (acceptances: string[], checkboxAcceptances: string) => [
        ...acceptances,
        ...checkboxAcceptances.split(","),
      ],
      []
    );

interface Props {
  showInfo: (infoType: InfoType) => () => void;
}

const TermsAcceptance: React.FC<Props> = ({ showInfo }) => {
  const params = new URLSearchParams(window.location.search);
  const skipIntro = JSON.parse(params.get("skip_intro")) || false;
  const [showDslExplanation, setShowDslExplanation] = useState(!skipIntro);
  const [isLoading, setIsLoading] = useState(false);
  const [postAcceptances, { data: acceptanceResponseData }] =
    usePostAcceptancesMutation();

  const onSubmit = async (values) => {
    setIsLoading(true);

    await postAcceptances({
      acceptanceTypes: getAcceptances(values),
    });

    setIsLoading(false);
  };

  useEffect(() => {
    if (acceptanceResponseData) {
      window.location.reload();
    }
  }, [acceptanceResponseData]);

  if (showDslExplanation)
    return <DslExplanation setShowDslExplanation={setShowDslExplanation} />;

  return (
    <FormContainer onSubmit={onSubmit}>
      <Styled.Header>
        <Heading.H2 tag="h1">
          Please accept the following terms to continue.
        </Heading.H2>
      </Styled.Header>

      <Styled.Alert onClick={showInfo(InfoType.patriotAct)} type="button">
        <BodySmall>
          IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT{" "}
          <u>Read more</u>
        </BodySmall>
      </Styled.Alert>

      <FormSection>
        {TERM_FIELDS.map((item) => (
          <CheckboxField
            key={item.name}
            name={item.name}
            value={item.value}
            label={<Label nodes={item.label} />}
            required
          />
        ))}
      </FormSection>

      <MobileFixedFooter>
        <Button isLoading={isLoading} type="submit">
          Agree & Continue
        </Button>
      </MobileFixedFooter>
    </FormContainer>
  );
};

export default TermsAcceptance;
