import React from "react";
import { Provider as StoreProvider } from "react-redux";
import { createGlobalStyle } from "styled-components";
import store from "store";
import FeatureFlagContextProvider from "components/context/FeatureFlagContext";
import { colors, fonts } from "utils/theme";
import {
  LayoutContextProvider,
  LayoutName,
} from "components/context/LayoutContext";
import { ModalContextProvider } from "components/context/ModalContext";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import * as process from "process";

(window as any).process = process;

const bugsnagApiKey = process.env.BUGSNAG_API_KEY;
if (bugsnagApiKey) {
  Bugsnag.start({
    apiKey: bugsnagApiKey,
    plugins: [new BugsnagPluginReact()],
  });
}

interface Props {
  children: React.ReactNode;
  layoutName?: LayoutName;
  withAuth?: boolean;
}

const GlobalStyle = createGlobalStyle`
  html {
    color: ${colors.midnight};
    font-family: ${fonts.body};

    * {
      box-sizing: border-box;
    }
  }

  html, body {
    margin: 0;
  }
`;

const App: React.FC<Props> = ({ children, layoutName, withAuth }) => (
  <StoreProvider store={store}>
    <FeatureFlagContextProvider withAuth={withAuth}>
      <GlobalStyle />
      <ModalContextProvider>
        <LayoutContextProvider name={layoutName}>
          {children}
        </LayoutContextProvider>
      </ModalContextProvider>
    </FeatureFlagContextProvider>
  </StoreProvider>
);

export default App;
