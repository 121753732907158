import { MaskType } from "./types";

// TODO: Add unit tests

const maskPhone = (inputValue) => {
  const match =
    inputValue.match(
      /(\()?([0-9]{1,3})?(\))?(\s)?([0-9]{1,3})?(-)?([0-9]{1,4})?/
    ) || [];
  let maskedValue = "";
  // eslint-disable-next-line
  const [_, openParen, group1, closeParen, space, group2, dash2, group3] =
    match;
  if (openParen || group1) maskedValue = `(${group1 || ""}`;
  if (closeParen || space || group2) maskedValue += `) ${group2 || ""}`;
  if (dash2 || group3) maskedValue += `-${group3 || ""}`;

  return maskedValue;
};

const maskDate = (inputValue) => {
  const match =
    inputValue.match(
      /(1[0-2]|0?[1-9]|0)?(\/)?(3[0-1]|[1-2][0-9]|0?[1-9]|0)?(\/)?([1-2][0-9]{0,3})?/
    ) || [];

  let maskedValue = "";
  // eslint-disable-next-line
  let [_, month, slash1, day, slash2, year] = match;

  if (month?.length < 2 && month < 10 && (month > 1 || (month > 0 && slash1))) {
    month = `0${month}`;
  }

  if (day?.length < 2 && day < 10 && (day > 3 || (day > 0 && slash2)))
    day = `0${day}`;

  if (month) maskedValue += `${month === undefined ? "" : month}`;
  if (slash1 || day) maskedValue += `/${day === undefined ? "" : day}`;
  if (slash2 || year) maskedValue += `/${year || ""}`;

  return maskedValue;
};

const maskSsn = (inputValue) => {
  const match =
    inputValue.match(/([0-9]{1,3})?(-)?([0-9]{1,2})?(-)?([0-9]{1,4})?/) || [];
  let maskedValue = "";
  // eslint-disable-next-line
  const [_, group1, dash1, group2, dash2, group3] = match;
  if (group1) maskedValue = `${group1 || ""}`;
  if (dash1 || group2) maskedValue += `-${group2 || ""}`;
  if (dash2 || group3) maskedValue += `-${group3 || ""}`;

  return maskedValue;
};

const getMaskFunction = (maskType: MaskType) => {
  switch (maskType) {
    case MaskType.phone:
      return maskPhone;
    case MaskType.date:
      return maskDate;
    case MaskType.ssn:
      return maskSsn;
    default:
      return (value) => value;
  }
};

export const getMaskedValue = (maskType: MaskType, inputValue: string) =>
  getMaskFunction(maskType)(inputValue);

export const getPattern = (maskType) => {
  switch (maskType) {
    case MaskType.phone:
      return `\\(\\d{3}\\) \\d{3}-\\d{4}`;
    case MaskType.date:
      return `\\d{2}\\/\\d{2}\\/\\d{4}`;
    case MaskType.ssn:
      return `\\d{3}-\\d{2}-\\d{4}`;
    default:
      return undefined;
  }
};
