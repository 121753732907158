import React from "react";
import { fontSizes } from "utils/theme";
import MarqetaContainer from "./MarqetaContainer";
import CardFront from "./CardFront";
import CardBack from "./CardBack";
import * as Styled from "./styled";

const BASE_STYLES = {
  "font-family": "monospace",
  "font-size": `${fontSizes.sm}px`,
  color: "white",
};

const CARD_ELEMENT_IDS = {
  cardPan: "display-card-pan",
  cardCvv: "display-card-cvv",
  cardExp: "display-card-exp",
};

const STYLES = {
  cardPan: BASE_STYLES,
  cardCvv: BASE_STYLES,
  cardExp: BASE_STYLES,
};

interface Props {
  amount: number;
  nextPayday?: string;
}

const ResetCard: React.FC<Props> = ({ amount, nextPayday }) => {
  const positiveAmount = Math.max(amount, 0);
  const isActive = positiveAmount > 0;
  // const isActive = true;

  return (
    <MarqetaContainer ids={CARD_ELEMENT_IDS} styles={STYLES}>
      {({ hide, show, detailsAreShown, isLoading }) => (
        <Styled.Wrapper
          $showDetails={detailsAreShown && !isLoading}
          $isActive={isActive}
        >
          <Styled.CardWrapper>
            <Styled.FrontWrapper>
              <CardFront
                amount={positiveAmount}
                isActive={isActive}
                nextPayday={nextPayday}
                showDetails={show}
                detailsAreLoading={isLoading}
              />
            </Styled.FrontWrapper>

            <Styled.BackWrapper>
              <CardBack
                hideDetails={hide}
                isActive={isActive}
                ids={CARD_ELEMENT_IDS}
                detailsAreShown={detailsAreShown}
              />
            </Styled.BackWrapper>
          </Styled.CardWrapper>
        </Styled.Wrapper>
      )}
    </MarqetaContainer>
  );
};

export default ResetCard;
