import React from "react";
import styled from "styled-components";
import { colors } from "utils/theme";

const Path = styled.path`
  fill: url(#SVGID_1_);
`;

const GradientStopOne = styled.stop`
  stop-color: ${colors.azure};
`;

const GradientStopTwo = styled.stop`
  stop-color: ${colors.heliotrope};
`;

const LogoMark = (props: any) => (
  <svg
    viewBox="0 0 49 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <linearGradient
      id="SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1="49"
      y1="49"
      x2="0"
      y2="0"
    >
      <GradientStopOne offset="0" />
      <GradientStopTwo offset="1" />
    </linearGradient>
    <Path
      d="M29.9,0H6.4v20c0,1.2,1,2.2,2.2,2.2h6.7V8.8h14.7c4.9,0,8.8,4,8.8,8.8v0c0,4.9-4,8.8-8.8,8.8H15.2H1.4
    l13.7,14.2v-5.4h11.7c1.6,0,3.1,0.8,3.8,2.2L37.4,49h7c1.4,0,2.3-1.5,1.6-2.7l-7.6-13.1c5.5-3,9.2-8.8,9.2-15.5v0
    C47.6,7.9,39.6,0,29.9,0z"
    />
  </svg>
);

export default LogoMark;
