import styled from "styled-components";
import ContentLayout from "components/layout/ContentLayout";
import * as Text from "components/ui/Text";
import { colors, mediaMax, mediaMin } from "utils/theme";

export const HeaderContent = styled(ContentLayout)`
  position: relative;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px;
  margin: 0 auto;
  padding-top: 120px;

  color: ${colors.midnight};
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Cta = styled.div`
  padding-top: 24px;
  width: 320px;
  max-width: 100%;

  a {
    width: 100%;
  }

  ${mediaMax("md")} {
    width: 100%;
  }
`;

export const AppImageWrapper = styled.div`
  position: relative;

  ${mediaMin("lg")} {
    &::after {
      content: "";

      position: absolute;
      bottom: 0;
      left: 21px;

      width: 379px;
      height: 25%;

      background: linear-gradient(to top, ${colors.azure}, ${colors.azure}00);
    }
  }
`;

export const AppImage = styled.img`
  display: block;
  width: 420px;
`;

export const AppImageMobile = styled.img`
  display: none;
`;

export const Total = styled.div`
  position: absolute;
  top: 29.5%;
  left: 0;

  display: flex;
  width: 100%;
  padding: 0 13%;

  color: white;

  font-size: 64px;

  &::before {
    content: "$";

    margin-right: -0.05em;

    opacity: 0.5;

    font-size: 24px;
  }
`;

export const LineItems = styled.div`
  position: absolute;
  top: 76%;
  right: 0;

  display: grid;
  justify-items: end;
  gap: 4.3em;
  padding: 0 11.5%;

  font-size: 16px;

  & > * {
    &::before {
      content: "$";
    }
  }

  * {
    color: ${colors.azure};
  }
`;

export const HeaderText = styled.div`
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Body = styled(Text.BodyLarge)`
  color: color-mix(in srgb, ${colors.midnight} 70%, transparent);
`;

export const BodySmall = styled(Text.BodySmall)`
  padding-top: 24px;

  color: color-mix(in srgb, ${colors.midnight} 50%, transparent);
`;

export const Wrapper = styled.section`
  position: relative;
  z-index: 1;

  overflow: hidden;
  padding-bottom: 300px;

  background: ${colors.flax};

  ${mediaMax("lg")} {
    padding-bottom: 0;

    ${HeaderContent} {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 48px;
      padding-top: 80px;

      text-align: center;
    }

    ${HeaderText} {
      align-items: center;
      padding-top: 0;
    }

    ${AppImage} {
      display: none;
    }

    ${AppImageMobile} {
      display: block;
      width: 100%;
      max-width: 320px;
    }

    ${Total}, ${LineItems} {
      display: none;
    }
  }

  @media screen and (min-width: 2200px) {
    padding-bottom: calc(300vw / 22);
  }
`;
